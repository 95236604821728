import '@popperjs/core';
import 'bootstrap';

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)
import Sortable from'sortablejs';
import htmx from 'htmx.org';
import Inputmask from "inputmask";
// import FroalaEditor from "froala-editor";
import FroalaEditor from 'froala-editor/js/froala_editor.pkgd.min';
import Tribute from "tributejs";

window.Tribute = Tribute;
window.FroalaEditor = FroalaEditor;
window.FROALA_KEY = 're1H1qB1D2B5C7D5F4G4iAa1Tb1YZNYAh1CUKUEQOHFVANUqD1G1F4J4B1B8C6D2B4F3=='
window.Sortable = Sortable;
window.Alpine = Alpine;
Alpine.start();
